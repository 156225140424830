import { visualization } from "@fscrypto/domain";
import { VisualizationType } from "@fscrypto/domain/visualization/v3";
import { Button, Card, Dropdown, Tooltip } from "@fscrypto/ui";
import { VizIcon } from "@fscrypto/viz-2";
import { useNavigate } from "@remix-run/react";
import { ChevronRightIcon, PlusIcon } from "lucide-react";
import { $path } from "remix-routes";
import { tracking } from "~/utils/tracking";
import { v2VisualizationFactory } from "../state/v2-visualization";
import { visualizationFactory } from "../state/visualization";

interface NewProps {
  queryId: string;
  showText?: boolean;
}

export const New = ({ queryId, showText }: NewProps) => {
  const navigate = useNavigate();
  const onChoose = async (t: VisualizationType) => {
    const viz = await visualizationFactory.createFromType(t, queryId);
    tracking("editor_create_chart", "Dashboard Beta", { queryId, vizId: viz.id });
    navigate($path("/studio/queries/:id/visualizations/:vizId", { id: queryId, vizId: viz.id }));
  };

  const onChooseLegacy = async (chartType: string) => {
    const newViz = visualization.newSchema.parse({ queryId, chartType, chart: { type: chartType } });
    const viz = await v2VisualizationFactory.create(newViz);
    tracking("editor_create_chart", "Dashboard Beta", { queryId, vizId: viz.id });
    navigate($path("/studio/queries/:id/visualizations/v2/:vizId", { id: queryId, vizId: viz.id }));
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Tooltip content="Add Visualization" side="top">
          <Button iconOnly={!showText} size="sm" className="relative top-1 text-white" variant="special">
            <PlusIcon className="size-5" />
            {showText && <span className="text-sm">{showText ? "Create Visualization" : ""}</span>}
          </Button>
        </Tooltip>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item>
          <VizIcon type="viz-table" size="xs" variant="button" label onClick={() => onChoose("viz-table")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="bar" size="xs" variant="button" label onClick={() => onChoose("bar")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="bar-stacked" size="xs" variant="button" label onClick={() => onChoose("bar-stacked")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="bar-normalized" size="xs" variant="button" label onClick={() => onChoose("bar-normalized")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="bar-line" size="xs" variant="button" label onClick={() => onChoose("bar-line")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="bar-horizontal" size="xs" variant="button" label onClick={() => onChoose("bar-horizontal")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon
            type="bar-horizontal-stacked"
            size="xs"
            variant="button"
            label
            onClick={() => onChoose("bar-horizontal-stacked")}
          />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon
            type="bar-horizontal-normalized"
            size="xs"
            variant="button"
            label
            onClick={() => onChoose("bar-horizontal-normalized")}
          />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="line" size="xs" variant="button" label onClick={() => onChoose("line")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="area" size="xs" variant="button" label onClick={() => onChoose("area")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon
            type="area-normalized"
            size="xs"
            variant="button"
            label
            onClick={() => onChoose("area-normalized")}
          />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="big-number" size="xs" variant="button" label onClick={() => onChoose("big-number")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="pie" size="xs" variant="button" label onClick={() => onChoose("pie")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="scatter" size="xs" variant="button" label onClick={() => onChoose("scatter")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="histogram" size="xs" variant="button" label onClick={() => onChoose("histogram")} />
        </Dropdown.Item>
        <Dropdown.Item>
          <VizIcon type="candlestick" size="xs" variant="button" label onClick={() => onChoose("candlestick")} />
        </Dropdown.Item>
        <Dropdown.Sub>
          <Dropdown.SubTrigger className="text-muted-foreground flex justify-between p-2 text-sm">
            <div>Legacy</div>
            <ChevronRightIcon />
          </Dropdown.SubTrigger>
          <Dropdown.Portal>
            <Dropdown.SubContent className="p-2" sideOffset={5} alignOffset={-5}>
              <Card>
                <Dropdown.Item>
                  <VizIcon type="bar" size="xs" variant="button" label onClick={() => onChooseLegacy("bar")} />
                </Dropdown.Item>
                <Dropdown.Item>
                  <VizIcon type="line" size="xs" variant="button" label onClick={() => onChooseLegacy("line")} />
                </Dropdown.Item>
                <Dropdown.Item>
                  <VizIcon type="area" size="xs" variant="button" label onClick={() => onChooseLegacy("area")} />
                </Dropdown.Item>
                <Dropdown.Item>
                  <VizIcon type="scatter" size="xs" variant="button" label onClick={() => onChooseLegacy("scatter")} />
                </Dropdown.Item>
                <Dropdown.Item>
                  <VizIcon type="pie" size="xs" variant="button" label onClick={() => onChooseLegacy("donut")} />
                </Dropdown.Item>
                <Dropdown.Item>
                  <VizIcon
                    type="big-number"
                    size="xs"
                    variant="button"
                    label
                    onClick={() => onChooseLegacy("big_number")}
                  />
                </Dropdown.Item>
              </Card>
            </Dropdown.SubContent>
          </Dropdown.Portal>
        </Dropdown.Sub>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
