import { profile } from "@fscrypto/domain";
import { QueryEditorSplitDirection } from "@fscrypto/domain/query";
import { getV3TypeFromLegacyChartType } from "@fscrypto/domain/visualization/v3";
import { Button, Text, Tooltip, cn } from "@fscrypto/ui";
import { VizIcon } from "@fscrypto/viz-2";
import { Link, useParams } from "@remix-run/react";
import { EllipsisVertical, FlipHorizontal, FlipVertical } from "lucide-react";
import { useRef } from "react";
import { $path } from "remix-routes";
import { useLocalStorage } from "usehooks-ts";
import { FileTreeIcon } from "~/features/file-explorer/components/file-explorer-row/file-tree-icon";
import { useResizeObserver } from "~/hooks/useResizeObserver";
import { tracking } from "~/utils/tracking";
import {
  useV2Visualization,
  useV2Visualizations,
  useVisualization,
  useVisualizations,
} from "../../visualization/hooks";
import { New } from "../../visualization/ui/new";
import { Options, V2Options } from "../../visualization/ui/visualization-toolbar";
import { useQuery } from "../state/query";

export const QueryVisTabs = ({
  queryId,
  isPublic,
  owner,
}: {
  queryId: string;
  isPublic?: boolean;
  owner?: profile.Profile;
}) => {
  const visualizations = useVisualizations(queryId);
  const v2Visualizations = useV2Visualizations(queryId);
  const params = useParams();
  const showText = [...visualizations, ...v2Visualizations].length === 0;
  return (
    <div className="bg-muted w-max-full flex-start relative flex h-12 flex-shrink-0 items-center gap-x-2 px-2">
      <QueryTab queryId={queryId} owner={owner} />
      {!isPublic && <New queryId={queryId} showText={showText} />}
      {visualizations.map((viz) => (
        <VizTab
          key={viz.id}
          queryId={queryId}
          vizId={viz.id}
          isActive={params.vizId === viz.id}
          isPublic={isPublic}
          owner={owner}
        />
      ))}
      {v2Visualizations.map((viz) => (
        <Viz2Tab
          key={viz.id}
          queryId={queryId}
          vizId={viz.id}
          isActive={params.vizId === viz.id}
          isPublic={isPublic}
          owner={owner}
        />
      ))}
      {!isPublic && !params.vizId && <ToggleSplit />}
    </div>
  );
};

const QueryTab = ({ queryId, isPublic, owner }: { queryId: string; isPublic?: boolean; owner?: profile.Profile }) => {
  const query = useQuery(queryId);
  const params = useParams();
  const to = !isPublic
    ? $path("/studio/queries/:id", { id: queryId })
    : $path("/:owner/q/:slugId/:slug", {
        owner: owner?.slug!,
        slugId: query?.query.slugId!,
        slug: query?.query.latestSlug!,
      });
  return (
    <Link to={to} prefetch="intent">
      <ActiveTabStyleContainer isActive={params.vizId === undefined} className="w-[200px]">
        <Tooltip content={query?.query.name} side="bottom">
          <div className="flex min-w-0 items-center">
            <FileTreeIcon type="query" size="lg" />
            <Text className="truncate" size="sm" weight={"semi"}>
              {query?.query.name}
            </Text>
          </div>
        </Tooltip>
      </ActiveTabStyleContainer>
    </Link>
  );
};

const ActiveTabStyleContainer = ({
  isActive,
  children,
  innerRef,
  className = "",
}: {
  isActive: boolean;
  children: React.ReactNode;
  innerRef?: React.Ref<HTMLDivElement>;
  className?: string;
}) => {
  return (
    <div
      ref={innerRef}
      className={cn(
        "relative top-[5px] flex h-10 w-full min-w-0 items-center gap-x-2 rounded-t-lg border-x border-t px-2",
        {
          "bg-background z-[9]": isActive,
          "hover:bg-content/10 bg-content/5 opacity-80": !isActive,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

interface VizTabProps {
  isActive: boolean;
  vizId: string;
  queryId: string;
  isPublic?: boolean;
  linkTo?: string;
  owner?: profile.Profile;
}
export const VizTab = ({ isActive, vizId, queryId, isPublic = false, linkTo, owner }: VizTabProps) => {
  const viz = useVisualization(vizId);
  const query = useQuery(queryId);
  const ref = useRef(null);
  const { width } = useResizeObserver(ref);
  if (!viz) return null;
  const title = viz.value.config.options.title?.text ?? "Untitled";
  const to = !isPublic
    ? $path("/studio/queries/:id/visualizations/:vizId", { id: queryId, vizId: vizId })
    : $path("/:owner/q/:slugId/:slug/visualizations/:vizId", {
        owner: owner?.slug!,
        slugId: query?.query.slugId!,
        slug: query?.query.latestSlug!,
        vizId: viz.value.id,
      });
  const trackOpen = () => tracking("editor_chart_open", "Dashboard Beta", { queryId, vizId });
  const trackDelete = () => tracking("editor_chart_tab_delete", "Dashboard Beta", { queryId, vizId });
  const trackClone = () => tracking("editor_chart_tab_clone", "Dashboard Beta", { queryId, vizId });

  return (
    <Link
      prefetch="intent"
      to={to}
      className="relative flex min-w-0 max-w-[200px] flex-1"
      ref={ref}
      onClick={trackOpen}
    >
      <ActiveTabStyleContainer isActive={isActive}>
        <Tooltip content={title} side="bottom">
          <div className="flex w-full min-w-0 items-center justify-center">
            <VizIcon type={viz.value.config.inputs.type} size="xs" isActive={isActive} />
            {width > 70 && (
              <div className="flex w-full items-center justify-between overflow-hidden">
                <Text className="truncate" size="sm">
                  {title}
                </Text>
                {!isPublic && (
                  <Options
                    visId={vizId}
                    customTrigger={<EllipsisVertical className="size-4 flex-shrink-0" />}
                    trackClone={trackClone}
                    trackDelete={trackDelete}
                  />
                )}
              </div>
            )}
          </div>
        </Tooltip>
      </ActiveTabStyleContainer>
    </Link>
  );
};

export const Viz2Tab = ({ isActive, vizId, queryId, isPublic = false, linkTo, owner }: VizTabProps) => {
  const viz = useV2Visualization(vizId);
  const query = useQuery(queryId);
  const ref = useRef(null);
  const { width } = useResizeObserver(ref);
  if (!viz) return null;
  const title = viz.value.title ?? "Untitled";
  const iconType = getV3TypeFromLegacyChartType(viz.value.chartType);
  const to = !isPublic
    ? $path("/studio/queries/:id/visualizations/v2/:vizId", { id: queryId, vizId: vizId })
    : $path("/:owner/q/:slugId/:slug/visualizations/v2/:vizId", {
        owner: owner?.slug!,
        slugId: query?.query.slugId!,
        slug: query?.query.latestSlug!,
        vizId: viz.value.id,
      });
  const track = () => tracking("editor_chart_open", "Dashboard Beta", { queryId, vizId });

  return (
    <Link prefetch="intent" to={to} className="relative flex min-w-0 max-w-[200px] flex-1" ref={ref} onClick={track}>
      <ActiveTabStyleContainer isActive={isActive}>
        <Tooltip content={title} side="bottom">
          <div className="flex min-w-0 items-center">
            <VizIcon type={iconType} size="xs" isActive={isActive} />
            {width > 70 && (
              <div className="flex w-full items-center justify-between overflow-hidden">
                <Text className="truncate" size="sm">
                  {title}
                </Text>
                {!isPublic && (
                  <V2Options visId={vizId} customTrigger={<EllipsisVertical className="size-4 flex-shrink-0" />} />
                )}
              </div>
            )}
          </div>
        </Tooltip>
      </ActiveTabStyleContainer>
    </Link>
  );
};

const ToggleSplit = () => {
  const [split, setSplit] = useLocalStorage<QueryEditorSplitDirection>("query-editor-split-direction", "vertical");

  const toggleSplit = () => {
    if (split === "horizontal") {
      setSplit("vertical");
      tracking("editor_layout_horizontal", "Dashboard Beta");
    } else {
      setSplit("horizontal");
      tracking("editor_layout_vertical", "Dashboard Beta");
    }
  };

  return (
    <Tooltip content={split === "vertical" ? "Vertical Split" : "Horizontal Split"} side="left">
      <Button variant="secondary" iconOnly size="sm" onClick={toggleSplit} className="ml-auto">
        {split === "vertical" ? <FlipHorizontal size={18} /> : <FlipVertical size={18} />}
      </Button>
    </Tooltip>
  );
};
